import "../../app.css";
import './quiz.css'
import AlertService from "../../../../services/alert.service";
import QuizMCQService from "../../../../services/quizmcq.service";
import AuthContext from "../../../../auth/auth-context";
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import MCQQuestion from "./question";

const QuizMCQ = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [quizId, setQuizId] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [deletedIds, setDeletedIds] = useState([]);
    const authContext = useContext(AuthContext);

    const quizAccess = authContext.getPermissions("quizmaster");
    if (quizAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }

    const addRow = () => {
        let maxId = questions.length + 1;
        let newQ = {
            quizId: params.id,
            question: '',
            orderBy: maxId,
            options: [
                {
                    option: '',
                    isAnswer: false,
                    orderBy: 1
                }, {
                    option: '',
                    isAnswer: false,
                    orderBy: 2
                }, {
                    option: '',
                    isAnswer: false,
                    orderBy: 3
                }, {
                    option: '',
                    isAnswer: false,
                    orderBy: 4
                }, {
                    option: 'e) None',
                    isAnswer: false,
                    orderBy: 5
                }
            ]
        };
        setQuestions(prev => {
            return [...prev, newQ];
        });
    };

    const loadQuestions = useCallback(async () => {
        setLoading(true);
        const data = await QuizMCQService.GetAll(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setQuestions(data.data);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, params.id]);

    useEffect(() => {
        if (questions.length === 0) {
            addRow();
        }
    }, [questions, addRow]);

    useEffect(() => {
        if (params.id) {
            setQuizId(params.id);
            loadQuestions();
        }
    }, [loadQuestions, authContext, params.id]);

    const onCancelHandler = async (event) => {
        navigate(`/master/quizzes/view/${params.id}`);
    };

    const changeHandler = (idx, input) => {
        setQuestions(prev => {
            if (prev && prev.length > 0) {
                prev[idx] = input;
            }
            return [...prev];
        });
    };

    const removeHandler = async (idx) => {
        let qId = questions[idx].id;
        setDeletedIds(prev => {
            if (qId > 0) {
                return [...prev, qId];
            } else {
                return prev;
            }
        });
        setQuestions(prev => {
            if (prev && prev.length > 0) {
                prev.splice(idx, 1);
            }
            return [...prev];
        });
    }

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (questions.length > 0) {
            setLoading(true);
            try {
                let payload = {
                    ToBeDeleted: deletedIds,
                    models: questions
                };
                let data = await QuizMCQService.BulkSave(payload, authContext);
                if (data.isSuccess) {
                    AlertService.success("Questions saved successfully!");
                    navigate(`/master/quizzes/view/${params.id}`);
                } else {
                    AlertService.error(data.errorMessage);
                }
                setLoading(false);
            } catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="question-container">
                <div className="row m-0">
                    <div className="col-4 course-title">
                        <span>Quiz</span>
                    </div>
                    <div className="col-4 course-title">
                        <center><span>MCQ</span></center>
                    </div>
                    <div className="col-4 course-title">
                        <button type="button" className="btn-incident-back" onClick={onCancelHandler}>
                            <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                        </button>
                    </div>
                </div>
                {!loading && questions && questions.length > 0 && questions.map((m, i) => (
                    <MCQQuestion key={i} index={i} input={m} changeHandler={changeHandler} removeHandler={removeHandler}></MCQQuestion>
                ))}
                <div className="row pt-1">
                    <div className="col-10">
                        <button
                            className="float-right cursor-pointer link-button"
                            onClick={addRow}
                        >
                            <i className="pi pi-plus"></i> Add Question
                        </button>
                    </div>
                    <div className="col-2"></div>
                </div>
                {loading && <div className="row m-0 pl-3 pr-3"><div className="col-12">Loading...</div></div>}
                {!loading && questions && questions.length > 0 &&
                    <div className="row pt-1">
                        <div className="col-12">
                            <button
                                className="float-right mr-3 submit-button"
                                onClick={onSubmitHandler}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                }
            </div>
        </Fragment >
    );
};

export default QuizMCQ;
