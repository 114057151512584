import './courses.css';
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AutoComplete } from "primereact/autocomplete";
import AuthContext from "../../../auth/auth-context";
import CourseService from "../../../services/course.service";
import AlertService from "../../../services/alert.service";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const UserVMs = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [addUser, setAddUser] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState(null);
    const [userVMs, setUserVMs] = useState([]);
    const [user, setUser] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [vmUrl, setVMUrl] = useState();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const authContext = useContext(AuthContext);

    const courseAccess = authContext.getPermissions("courses");
    if (courseAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }
    const showAdd = courseAccess.filter((f) => f.access.includes(2)).length > 0;
    // const showEdit = courseAccess.filter((f) => f.access.includes(3)).length > 0;

    const loadUserVMs = useCallback(async () => {
        setLoading(true);
        const data = await CourseService.GetUserVMs(params.id, authContext);
        if (data.isSuccess) {
            setUserVMs(data.data);
            setLoading(false);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [params, authContext]);

    const loadUsers = useCallback(async () => {
        setLoading(true);
        const data = await CourseService.GetCourseUsers(params.id, authContext);
        if (data.isSuccess) {
            setUsers(data.data);
            setLoading(false);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [params, authContext]);

    useEffect(() => {
        if (params.id) {
            loadUserVMs();
            loadUsers();
        }
    }, [params, loadUserVMs, loadUsers, authContext]);

    const search = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _filteredUsers;

            if (!event.query.trim().length) {
                _filteredUsers = [...users];
            }
            else {
                _filteredUsers = users.filter(f => userVMs.filter(a => a.courseUserId === f.value).length === 0).filter((user) => {
                    return user.label.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredUsers(_filteredUsers);
        }, 250);
    }

    const addUserVMHandler = (e) => {
        e.preventDefault();
        setUser('');
        setVMUrl('');
        setUsername('');
        setPassword('');
        setAddUser(true);
    }

    const saveUserVMHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        let payload = {
            courseUserId: selectedUser,
            displayName: '',
            type: 0,
            vmUrl: vmUrl,
            userName: username,
            password: password
        };
        let result = await CourseService.SaveUserVM(payload, authContext);
        if (result.isSuccess) {
            loadUserVMs();
            setUser('');
            setVMUrl('');
            setUsername('');
            setPassword('');
            setAddUser(false);
            setLoading(false);
        } else {
            AlertService.error(result.errorMessage);
        }
        setLoading(false);
    }

    const cancelUserVMHandler = (e) => {
        e.preventDefault();
        setUser('');
        setVMUrl('');
        setUsername('');
        setPassword('');
        setAddUser(false);
    }

    const removeUserVMHandler = async (id) => {
        let data = await CourseService.RemoveUserVM(id, authContext);
        if (data.isSuccess) {
            await loadUserVMs();
            AlertService.success("User details deleted successfully!");
        } else {
            AlertService.error(data.errorMessage);
        }
    }

    const onConfirmDeleteHandler = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete the selected user?",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: () => removeUserVMHandler(id),
        });
    };

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-12 course-title">
                    <button type="button" className="btn-incident-back" onClick={() => navigate('/courses')}>
                        <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                    </button>
                </div>
            </div>
            <div className="row m-0">
                <div className='col-1'><div className='vm-title'>S.No</div></div>
                <div className='col-3'><div className='vm-title'>Username</div></div>
                <div className='col-3'><div className='vm-title'>VM URLs</div></div>
                <div className='col-5'><div className='vm-title'>VM Credenials</div></div>
                <ConfirmPopup />
            </div>
            {
                userVMs && userVMs.length > 0 && userVMs.map((uservm, i) => (
                    <div className="row m-0" key={i}>
                        <div className='col-1'><div className='vm-value'>{i + 1}</div></div>
                        <div className='col-3'><div className='vm-value'>{uservm.displayName}</div></div>
                        <div className='col-3'><div className='vm-value'><i title='Copy' onClick={() => { navigator.clipboard.writeText(uservm.vmUrl); AlertService.info('Copied!!'); }} className='fa fa-clone'></i> {uservm.vmUrl}</div></div>
                        <div className='col-5'>
                            <div className="row m-0">
                                <div className='col-5 p-0'>
                                    <div className='vm-value'><i title='Copy' onClick={() => { navigator.clipboard.writeText(uservm.userName); AlertService.info('Copied!!'); }} className='fa fa-clone'></i> {uservm.userName}</div>
                                </div>
                                <div className='col-6 p-0'>
                                    <div className='vm-value' title={uservm.password}><i title='Copy' onClick={() => { navigator.clipboard.writeText(uservm.password); AlertService.info('Copied!!'); }} className='fa fa-clone'></i> {uservm.password}</div>
                                </div>
                                <div className='col-1 p-0'>
                                    <button onClick={(e) => onConfirmDeleteHandler(e, uservm.id)} className='vm-remove-button' disabled={loading}><i className='fa fa-trash'></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
            {
                showAdd && addUser === false &&
                <div className="row m-0">
                    <div className='col-12 mx-2'>
                        <button onClick={addUserVMHandler} className='vm-add-button'><i className='fa fa-plus'></i></button>
                    </div>
                </div>
            }
            {
                showAdd && addUser &&
                <div className="row m-0">
                    <div className='col-3'>
                        <div className='vm-value'>
                            <AutoComplete field="label" value={user} suggestions={filteredUsers} completeMethod={search} onChange={(e) => {
                                if (e.value && e.value.value) {
                                    setSelectedUser(e.value.value);
                                }
                                setUser(e.value);
                            }} disabled={loading} />
                            {/* <input className='form-control' placeholder='Username'></input> */}
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='vm-value'>
                            <input className='form-control' placeholder='VM Url' defaultValue={vmUrl} onChange={(e) => setVMUrl(e.target.value)} disabled={loading}></input>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="row m-0">
                            <div className='col-6 p-0'>
                                <div className='vm-value'>
                                    <input className='form-control' placeholder='Username' defaultValue={username} onChange={(e) => setUsername(e.target.value)} disabled={loading}></input>
                                </div>
                            </div>
                            <div className='col-6 p-0'>
                                <div className='vm-value'>
                                    <input className='form-control' placeholder='Password' defaultValue={password} onChange={(e) => setPassword(e.target.value)} disabled={loading}></input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'>
                        <button onClick={saveUserVMHandler} className='vm-add-button' disabled={loading}><i className='fa fa-check'></i></button>
                        <button onClick={cancelUserVMHandler} className='vm-cancel-button mx-2' disabled={loading}><i className='fa fa-times'></i></button>
                    </div>
                </div>}
        </Fragment >
    );
};

export default UserVMs;
