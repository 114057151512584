import { Fragment, useContext, useState } from "react";
import SupportService from "../../../services/support.service";
import AuthContext from "../../../auth/auth-context";
import Viewer from "../documents/viewer";
import { Sidebar } from "primereact/sidebar";
import AlertService from "../../../services/alert.service";

const Attachment = ({ no, attachment }) => {
  no++;
  const authContext = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [pdfData, setPDFData] = useState();
  const [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    setLoading(true);
    const data = await SupportService.GetFile(attachment.id, authContext);
    if (data.isSuccess) {
      setPDFData(`data:${data.data.contentType};base64,${data.data.filePath}`);
      setVisible(true);
    } else {
      AlertService.error(data.errorMessage);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <div className="attachment">
        {no}.{" "}
        <button className={`btn-icon ${loading ? 'loading' : ''}`} onClick={downloadFile} title="Click on the filename to preview the file.">
          {attachment.fileName}{loading ? ' ...' : ''}
        </button>
        <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen>
          {pdfData && <Viewer src={pdfData}></Viewer>}
        </Sidebar>
      </div>
    </Fragment>
  );
};

export default Attachment;
