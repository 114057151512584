import "../../app.css";
import AlertService from "../../../../services/alert.service";
import AssessmentService from "../../../../services/assessment.service";
import CourseService from "../../../../services/course.service";
import AuthContext from "../../../../auth/auth-context";
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from "primereact/checkbox";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";

const AddAssessment = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  const [assessmentId, setAssessmentId] = useState(0);
  const [assessmentName, setAssessmentName] = useState();
  const [courseId, setCourseId] = useState();
  const [mcq, setMCQ] = useState(false);
  const [practical, setPractical] = useState(false);

  const [assessmentNameIsValid, setAssessmentNameIsValid] = useState(true);
  const [courseIdIsValid, setCourseIdIsValid] = useState(true);

  const assessmentNameRef = useRef();

  const authContext = useContext(AuthContext);

  const assessmentAccess = authContext.getPermissions("assessmentsmaster");
  if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
    navigate("/");
  }
  const showAdd = assessmentAccess.filter((f) => f.access.includes(2)).length > 0;
  const showEdit = assessmentAccess.filter((f) => f.access.includes(3)).length > 0;
  const showDelete = assessmentAccess.filter((f) => f.access.includes(4)).length > 0;

  const loadCourses = useCallback(async () => {
    setLoading(true);
    const data = await CourseService.GetCourses(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setCourses(data.data);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses, authContext]);

  const loadAssessment = useCallback(async () => {
    setLoading(true);
    const data = await AssessmentService.Get(params.id, authContext);
    if (data.isSuccess) {
      setLoading(false);
      setAssessmentName(data.data.assessmentName);
      setCourseId(data.data.courseId);
      setMCQ(data.data.mcq);
      setPractical(data.data.practical);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext, params.id]);

  useEffect(() => {
    if (params.id) {
      setAssessmentId(params.id);
      loadAssessment();
    }
  }, [loadAssessment, authContext, params.id]);

  const onCancelHandler = async (event) => {
    navigate("/master/assessments");
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    let isValid = true;
    let assessmentNameValue = assessmentNameRef.current.value;
    if (!assessmentNameValue) {
      setAssessmentNameIsValid(false);
      isValid = false;
    }
    if (!courseId) {
      setCourseIdIsValid(false);
      isValid = false;
    }

    if (isValid) {
      setLoading(true);
      try {
        let payload = {
          assessmentName: assessmentNameValue,
          courseId: courseId,
          mcq: mcq,
          practical: practical
        };
        if (assessmentId > 0) {
          payload.id = assessmentId;
        }
        let data = await AssessmentService.Save(payload, authContext);
        if (data.isSuccess) {
          AlertService.success('Assessment details are saved successfully!');
          navigate("/master/assessments");
        } else {
          AlertService.error(data.errorMessage);
        }
        setLoading(false);
      } catch (ex) {
        console.log(ex);
        setLoading(false);
      }
    }
  };

  const onConfirmDeleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete this assessment?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: onDeleteHandler,
    });
  };

  const onDeleteHandler = async () => {
    let data = await AssessmentService.Remove(assessmentId, authContext);
    if (data.isSuccess) {
      AlertService.success("Assessment details are deleted successfully!");
      navigate("/master/assessments");
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  return (
    <Fragment>
      {!loading && (
        <form onSubmit={onSubmitHandler}>
          <div className="row pt-4 catalogue">
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
            <div className="col-md-2 col-sm-3 col-xs-12 label">Assessment Name</div>
            <div className="col-md-8 col-sm-5 col-xs-12 value">
              <input
                type="text"
                className="form-control"
                placeholder="Assessment Name"
                maxLength="150"
                defaultValue={assessmentName}
                ref={assessmentNameRef}
                onChange={(e) => {
                  setAssessmentName(e.value);
                  setAssessmentNameIsValid(true);
                }}
              />
              {!assessmentNameIsValid && (
                <p className="error">Please provide assessment name</p>
              )}
            </div>
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
          </div>
          <div className="row pt-4">
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
            <div className="col-md-2 col-sm-3 col-xs-12 label">Course</div>
            <div className="col-md-8 col-sm-5 col-xs-12 value">
              <Dropdown value={courseId} onChange={(e) => setCourseId(e.value)} options={courses} optionLabel="label"
                placeholder="Select a course" className="w-full" />
              {!courseIdIsValid && (
                <p className="error">Please select a course</p>
              )}
            </div>
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
          </div>
          <div className="row pt-4">
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
            <div className="col-md-2 col-sm-3 col-xs-12 label">Assessment Type</div>
            <div className="col-md-8 col-sm-5 col-xs-12 value">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="mcqId"
                  onChange={(e) => setMCQ(e.checked)}
                  checked={mcq}
                ></Checkbox><label htmlFor="mcqId" className="ml-2 mt-1 mr-2"> MCQ</label>
              </div>
              <div className="flex align-items-center">
                <Checkbox
                  inputId="practicalId"
                  onChange={(e) => setPractical(e.checked)}
                  checked={practical}
                ></Checkbox><label htmlFor="practicalId" className="ml-2 mt-1"> Practical</label>
              </div>
            </div>
            <div className="col-md-1 col-sm-2 d-xs-none"></div>
          </div>
          <div className="row pt-2 mb-3">
            <div className="col-md-12 col-sm-12">
              <div className="text-center pt-4">
                {((showAdd && assessmentId === 0) || (showEdit && assessmentId > 0)) &&
                  <button
                    type="submit"
                    className="submit-button"
                    disabled={loading}>
                    Submit
                  </button>}
                {showDelete && assessmentId > 0 && (
                  <>
                    <ConfirmPopup />
                    <button
                      type="button"
                      className="remove-button ml-3"
                      onClick={onConfirmDeleteHandler}
                      disabled={loading}
                    >
                      Delete
                    </button>
                  </>
                )}
                <button
                  type="button"
                  className="cancel-button ml-3"
                  onClick={onCancelHandler}
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      )
      }
    </Fragment >
  );
};

export default AddAssessment;
