// return <iframe title={'Cyber Bay'} src="https://cybersecurity.nuvepro.io/"></iframe>;

import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../auth/auth-context";
import CourseService from "../../../services/course.service";
import VMService from "../../../services/vm.service";
import AlertService from "../../../services/alert.service";

const Bays = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [mappedCourses, setMappedCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(0);
  const authContext = useContext(AuthContext);

  const loadCourses = useCallback(async () => {
    setLoading(true);
    const data = await CourseService.GetMappedCourses(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setMappedCourses(data.data);
      if (data.data.length === 1) {
        setSelectedCourse(data.data[0].courseId);
      }
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses, authContext]);

  const startVM = async (e) => {
    e.preventDefault();
    await VMService.startVM(authContext);
  }

  return (
    <Fragment>
      <div className="row m-0 pl-3 pr-3">
        <div className="col-6">
          <h1 className="title">Cyber Commandos</h1>
        </div>
        <div className="col-6">
          <button type="button" className="btn-incident-back" onClick={() => navigate('/')}>
            <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
          </button>
        </div>
      </div>
      {
        selectedCourse === 0 && mappedCourses.length === 0 &&
        <div className="row m-0 pl-3 pr-3">
          <div className="col-12 text-center">
            No Course Available!!
          </div>
        </div>
      }
      {
        selectedCourse === 0 && mappedCourses.length > 1 &&
        <div className="row m-0 pl-3 pr-3">
          {
            mappedCourses.map((m, i) => (
              <div key={m.courseId} className="col-3 text-center cursor-pointer" onClick={() => setSelectedCourse(m.courseId)}>
                <div className="commando-container">
                  <div className="title">Lab {i + 1}</div>
                  <div className="body">
                    <span>{m.courseName}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      }
      {
        selectedCourse > 0 &&
        <div className="row m-0 pl-3 pr-3 justify-content-end">
          <div className="col-6 text-center">
            <div className="commando-container">
              <div className="title">Cyber Range</div>
              <div className="body">
                <div>
                  <label>Total Hours: </label><span> 15 Hours</span>
                </div>
                <div>
                  <label>Consumed Hours: </label><span> 2h 30m</span>
                </div>
                <div>
                  <label>Remaining Hours: </label><span> 12h 30m</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 text-right">
            <button className="submit-button" onClick={startVM}>
              Start Lab / Stop Lab
            </button>
          </div>
        </div>
      }
    </Fragment>
  );
};

export default Bays;
