import "../app.css";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import AuthContext from "../../../auth/auth-context";
import CatalogueService from "../../../services/catalogue.service";
import AlertService from "../../../services/alert.service";
import TimerService from "../../../services/timer.service";
import Card from "./card";
import View from "./view";

const Catalogues = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [incidents, setIncidents] = useState([]);
  const [groupedIncidents, setGroupedIncidents] = useState([]);
  const [incidentsData, setIncidentsData] = useState([]);
  const [incident, setIncident] = useState({});
  const [courseId, setCourseId] = useState();
  const [categoryId, setCategoryId] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [courseName, setCourseName] = useState('Incident Details');
  const [timeSpent, setTimeSpent] = useState(0);
  const courseIdRef = useRef();
  const incidentIdRef = useRef();
  const timeSpentRef = useRef();
  const scrollRef = useRef();

  const authContext = useContext(AuthContext);

  const responsiveOptions = [
    {
      breakpoint: "767px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 2,
      numScroll: 1,
    },
  ];

  const catalogueAccess = authContext.getPermissions("catalogues");
  if (catalogueAccess.filter((f) => f.access.includes(1)).length === 0) {
    navigate("/");
  }
  const showAdd = catalogueAccess.filter((f) => f.access.includes(2)).length > 0;
  const showEdit = catalogueAccess.filter((f) => f.access.includes(3)).length > 0;
  const showDelete = catalogueAccess.filter((f) => f.access.includes(4)).length > 0;

  const groupIncidents = (incidents) => {
    let preGroupedData = Object.groupBy(incidents, ({ category }) => category);
    let groupedData = [];
    for (let key in preGroupedData) {
      groupedData.push({ key, items: preGroupedData[key] });
    }
    return groupedData;
  }

  const loadIncidents = useCallback(async () => {
    setLoading(true);
    const data = await CatalogueService.GetAll(searchText, authContext);
    if (data.isSuccess) {
      setLoading(false);
      let i = 0;
      data.data.forEach((element) => {
        i++;
        element.no = i;
      });
      setIncidents(data.data);
      setGroupedIncidents(groupIncidents(data.data));
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [searchText, authContext]);

  const loadIncidentsByCourse = useCallback(async () => {
    setLoading(true);
    const data = await CatalogueService.GetAllByCourseId(params.id, searchText, authContext);
    if (data.isSuccess) {
      setLoading(false);
      let i = 0;
      data.data.incidents.forEach((element) => {
        i++;
        element.no = i;
      });
      setIncidents(data.data.incidents);
      setGroupedIncidents(groupIncidents(data.data.incidents));
      setCourseName(data.data.course.courseName);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [params, searchText, authContext]);

  const loadIncidentsByCategory = useCallback(async () => {
    setLoading(true);
    const data = await CatalogueService.GetAllByCategoryId(params.categoryid, searchText, authContext);
    if (data.isSuccess) {
      setLoading(false);
      let i = 0;
      data.data.incidents.forEach((element) => {
        i++;
        element.no = i;
      });
      setIncidents(data.data.incidents);
      setGroupedIncidents(groupIncidents(data.data.incidents));
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [params, searchText, authContext]);

  useEffect(() => {
    setCategoryId(0);
    setCourseId(0);
    if (params.id) {
      setCourseId(Number(params.id));
      loadIncidentsByCourse();
    } else if (params.categoryid) {
      setCategoryId(Number(params.categoryid));
      loadIncidentsByCategory();
    } else {
      if (authContext.roleId === 7) {
        navigate('/courses');
      } else {
        loadIncidents();
      }
    }
  }, [params, navigate, loadIncidentsByCourse, loadIncidentsByCategory, loadIncidents, authContext]);

  const onCardClick = async (id) => {
    await updateTimeSpent();
    let preId = selectedCardId;
    setTimeSpent(0);
    setSelectedCardId(id);
    loadIncident(id);
    if (preId === undefined || preId === null || preId === 0) {
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView();
        }
      }, 10);
    }
  };

  useEffect(() => {
    courseIdRef.current = courseId;
    incidentIdRef.current = selectedCardId;
  }, [courseId, selectedCardId]);

  useEffect(() => {
    timeSpentRef.current = timeSpent;
  }, [timeSpent]);

  const updateTimeSpent = async () => {
    if (selectedCardId && timeSpent) {
      let payload = {
        courseId: courseId,
        incidentId: selectedCardId,
        timeSpent: timeSpent
      };
      await TimerService.Save(payload, authContext);
    }
  }

  const loadIncident = useCallback(
    async (cardId) => {
      setLoading(true);
      if (incidentsData.filter(f => f.id === cardId).length === 0) {
        const data = await CatalogueService.Get(cardId, authContext);
        if (data.isSuccess) {
          setLoading(false);
          setIncident(data.data);
          setIncidentsData(prevData => {
            if (prevData.filter(f => f.id === data.data.id).length === 0) {
              return [...prevData, data.data]
            }
            return prevData;
          });
        } else {
          setLoading(false);
          AlertService.error(data.errorMessage);
        }
      }
      else {
        setLoading(false);
        setIncident(incidentsData.filter(f => f.id === cardId)[0]);
      }
    },
    [authContext, incidentsData]
  );

  const cardTemplate = (incident) => {
    return (
      <Card
        id={incident.id}
        no={incident.no}
        title={incident.incidentTitle}
        active={incident.id === selectedCardId}
        clickHandler={() => onCardClick(incident.id)}
      ></Card>
    );
  };

  const onConfirmDeleteHandler = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete the selected incident?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: onDeleteHandler,
    });
  };

  const onDeleteHandler = async () => {
    let data = await CatalogueService.Remove(selectedCardId, authContext);
    if (data.isSuccess) {
      await clearSelection();
      await loadIncidents();
      AlertService.success("Incident details deleted successfully!");
    } else {
      AlertService.error(data.errorMessage);
    }
  };

  const onEditHandler = async () => {
    navigate("/catalogues/edit/" + incident.id);
  };

  const onAddHandler = async () => {
    navigate("/catalogues/add");
  };

  const clearSelection = async () => {
    await updateTimeSpent();
    setTimeSpent(0);
    setSelectedCardId(0);
    setIncident(null);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeSpent(prevTime => prevTime + 1);
    }, 1000);
    return () => clearInterval(intervalId)
  }, [authContext]);

  /*Dependency array should be empty to run only during component unmount. */
  useEffect(() => {
    return () => {
      const clearData = async () => {
        if (incidentIdRef.current && timeSpentRef.current) {
          let payload = {
            courseId: courseIdRef.current,
            incidentId: incidentIdRef.current,
            timeSpent: timeSpentRef.current
          };
          await TimerService.Save(payload, authContext);
        }
      }
      clearData();
    }
  }, []);

  const handleScroll = event => {
    console.log(event.currentTarget.scrollTop);
  };

  const statusHandler = async (event) => {
    setIncident(inci => {
      if (inci != null) {
        inci.status = event;
      }
      return inci;
    });
    setGroupedIncidents(gs => {
      for (let g of gs) {
        let items = g.items.filter(f => f.id === incident.id);
        if (items.length > 0) {
          items[0].status = event;
        }
      }
      return gs;
    });
    setIncidents(is => {
      let items = is.filter(f => f.id === incident.id);
      if (items.length > 0) {
        items[0].status = event;
      }
      return is;
    });
    setIncidentsData(is => {
      let items = is.filter(f => f.id === incident.id);
      if (items.length > 0) {
        items[0].status = event;
      }
      return is;
    });
  }

  return (
    <Fragment>
      {selectedCardId === 0 && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <div className="form-group has-search search-group catalogue-search">
                <span className="fa fa-search form-control-feedback"></span>
                <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} className="form-control" placeholder="Search" />
                {searchText && <span className="input-group-append">
                  <button className="btn border-0" type="button" onClick={() => setSearchText('')}>
                    <i className="fa fa-times"></i>
                  </button>
                </span>}
              </div>
            </div>
            <div className="col-3"></div>
            <div className="col-6 course-title">
              <button type="button" className="btn-incident-back" onClick={() => navigate('/types')}>
                <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
              </button>
              {categoryId > 0 && <a className="btn-incidents-all float-right" href="#/catalogues">
                View All Incidents
              </a>}
            </div>
          </div>
          {
            groupedIncidents &&
            groupedIncidents.map((g, i) => (
              <div key={i}>
                <div className="row">
                  <div className="col-12 text-center">
                    <center>
                      <span className="btn-incidents-all btn-incidents-width">
                        {g.key}
                      </span>
                    </center>
                  </div>
                </div>
                <div className="row">
                  {g.items &&
                    g.items.map((incident, i) => (
                      <div key={i} className="col-lg-2 col-md-4 col-sm-6 col-12">
                        <Card
                          no={incident.no}
                          id={incident.id}
                          title={incident.incidentTitle}
                          active={incident.id === selectedCardId}
                          clickHandler={onCardClick}
                          status={incident.status}
                        ></Card>
                      </div>
                    ))}
                </div>
              </div>
            ))
          }
          {/* {incidents &&
              incidents.map((incident, i) => (
                <div key={i} className="col-lg-2 col-md-4 col-sm-6 col-12">
                  <Card
                    no={incident.no}
                    id={incident.id}
                    title={incident.incidentTitle}
                    active={incident.id === selectedCardId}
                    clickHandler={onCardClick}
                  ></Card>
                </div>
              ))} */}
          {incidents && incidents.length === 0 && (
            <div className="col-12">No incidents available...</div>
          )}
        </div>
      )}
      {selectedCardId > 0 && (
        <>
          <div className="row d-none d-md-flex">
            <div className="col-md-2 col-sm-4">
              <div className="form-group has-search search-group catalogue-search">
                <span className="fa fa-search form-control-feedback"></span>
                <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} className="form-control" placeholder="Search" />
                {searchText && <span className="input-group-append">
                  <button className="btn border-0" type="button" onClick={() => setSearchText('')}>
                    <i className="fa fa-times"></i>
                  </button>
                </span>}
              </div>
              <div className="incident-list incident-v-scroll" onScroll={handleScroll}>
                <div className="row m-0">
                  {
                    groupedIncidents &&
                    groupedIncidents.map((g, i) => (
                      <div key={i}>
                        <div className="col-12 text-center">
                          <center>
                            <span className="btn-incidents-all">
                              {g.key}
                            </span>
                          </center>
                        </div>
                        {g.items &&
                          g.items.map((incident, i) => (
                            <div key={i} className="col-12" ref={incident.id === selectedCardId ? scrollRef : null}>
                              <Card
                                no={incident.no}
                                id={incident.id}
                                title={incident.incidentTitle}
                                active={incident.id === selectedCardId}
                                clickHandler={onCardClick}
                                status={incident.status}
                              ></Card>
                            </div>
                          ))}
                      </div>
                    ))
                  }
                </div>
                {/* <Carousel
                  value={incidents}
                  numVisible={3}
                  numScroll={1}
                  page={0}
                  circular={true}
                  orientation="vertical"
                  showIndicators={false}
                  verticalViewPortHeight="calc(100vh - 220px)"
                  itemTemplate={cardTemplate}
                /> */}
              </div>
            </div>
            <div className="col-md-10 col-sm-8 incident-view">
              {!loading && <View clearSelection={clearSelection} statusHandler={statusHandler} incident={incident} courseName={courseName}></View>}
            </div>
          </div>
          <div className="row d-md-none d-sm-flex d-flex h-carousel-container">
            <Carousel
              value={incidents}
              numVisible={3}
              numScroll={1}
              page={0}
              showIndicators={false}
              responsiveOptions={responsiveOptions}
              itemTemplate={cardTemplate}
              circular={true}
              className="h-carousel"
            />
          </div>
          <div className="row d-md-none d-sm-flex d-flex h-incident-view">
            {!loading && incident !== undefined && incident !== null && incident.id === selectedCardId && (
              <View clearSelection={clearSelection} statusHandler={statusHandler} incident={incident} courseName={courseName} courseId={courseId} editable={showEdit}></View>
            )}
          </div>
        </>
      )}
      {showAdd && (
        <button
          type="button"
          onClick={onAddHandler}
          className="floating-button"
        >
          <i className="fa fa-plus"></i>
        </button>
      )}
      {selectedCardId > 0 && showEdit && (
        <button
          type="button"
          onClick={onEditHandler}
          className="floating-button mr-6"
        >
          <i className="fa fa-pencil"></i>
        </button>
      )}
      {selectedCardId > 0 && showDelete && (
        <>
          <ConfirmPopup />
          <button
            type="button"
            onClick={onConfirmDeleteHandler}
            className="floating-button remove"
            style={{ "marginRight": '6rem' }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </>
      )
      }
    </Fragment >
  );
};

export default Catalogues;
