import request from "./request";

const QuizMCQExamService = {
  GetAll: async (id, context) => {
    return await request.sendRequest(`/quizmcqexam/all/${id}`, context.token);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/quizmcqexam`, payload, context);
  },
};

export default QuizMCQExamService;
