import "../app.css";
import '../masters/quiz/quiz.css'
import AlertService from "../../../services/alert.service";
import QuizMCQExamService from "../../../services/quizmcqexam.service";
import AuthContext from "../../../auth/auth-context";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import MCQExamQuestion from "./question";
import { useTimer } from 'react-timer-hook';

const QuizExam = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [quizExam, setQuizExam] = useState();
    const [questions, setQuestions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState();
    const authContext = useContext(AuthContext);
    const [selectedCardId, setSelectedCardId] = useState(0);
    const scrollRef = useRef();
    const [showTimeDuration, setShowTimeDuration] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        restart,
    } = useTimer({
        expiryTimestamp: 0, onExpire: () => {
            setIsExpired(true);
        }
    });

    const quizAccess = authContext.getPermissions("quiz");
    if (quizAccess.filter((f) => f.access.includes(1)).length === 0) {
        navigate("/");
    }

    const loadQuestions = useCallback(async () => {
        setLoading(true);
        const data = await QuizMCQExamService.GetAll(params.id, authContext);
        if (data.isSuccess) {
            setLoading(false);
            setQuizExam(data.data);
            setQuestions(data.data.questions);
            setSelectedCardId(data.data.questions[0].id);
            if (data.data.isMCQExamCompleted === false) {
                const time = new Date();
                time.setSeconds(time.getSeconds() + (data.data.duration * 60));
                setShowTimeDuration(true);
                restart(time);
            } else {
                setIsExpired(true);
            }
            setSelectedIndex(0);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, restart, params.id]);

    useEffect(() => {
        if (params.id) {
            loadQuestions();
        }
    }, [loadQuestions, authContext, params.id]);

    const onCancelHandler = async (event) => {
        navigate(`/quizzes`);
    };

    const changeHandler = (idx, input) => {
        setQuestions(prev => {
            if (prev && prev.length > 0) {
                prev[idx] = input;
            }
            return [...prev];
        });
    };

    const onCardClick = (data, i) => {
        let preId = selectedCardId;
        setSelectedCardId(data.id);
        setSelectedIndex(i);
        if (preId === undefined || preId === null || preId === 0) {
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView();
                }
            }, 10);
        }
    }

    const moveToPrevious = (e) => {
        setSelectedIndex(prev => {
            if (prev > 0) {
                return prev - 1;
            } else {
                return 0;
            }
        });
    }

    const moveToNext = (e) => {
        setSelectedIndex(prev => {
            if (prev === (questions.length - 1)) {
                return prev;
            } else {
                return prev + 1;
            }
        });
    }

    useEffect(() => {
        localStorage.setItem('ExamTimer', totalSeconds);
    }, [totalSeconds]);

    const onSubmitHandler = async () => {
        setLoading(true);
        try {
            let payload = quizExam;
            payload.quizId = params.id;
            let data = await QuizMCQExamService.Save(payload, authContext);
            if (data.isSuccess) {
                AlertService.success("Quiz saved successfully!");
                navigate(`/quizzes`);
            } else {
                AlertService.error(data.errorMessage);
            }
            setLoading(false);
        } catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    };

    const onConfirmDeleteHandler = (event) => {
        setQuizExam(prev => {
            prev.questions = questions;
            return prev;
        });
        confirmPopup({
            target: event.currentTarget,
            message: "Are your sure you willing to submit the Quiz",
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            accept: onSubmitHandler,
        });
    };

    return (
        <Fragment>
            <div className="container-fluid question-container">
                <div className="row d-none d-md-flex">
                    <div className="col-md-2 col-sm-4">
                        <center className="quiz-left-title">MCQ</center>
                        <div className="incident-list incident-v-scroll border">
                            <div className="row m-0">
                                {
                                    questions && questions.length > 0 &&
                                    questions.map((q, i) => (
                                        <div key={i} className="col-12" ref={q.id === selectedCardId ? scrollRef : null}>
                                            <div
                                                className={q.id === selectedCardId ? "quiz-card p-1 text-center active" : "quiz-card p-1 text-center"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onCardClick(q, i);
                                                }}
                                            >
                                                <div className="name" title={q.question}>
                                                    Question {i + 1}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 col-sm-8 view">
                        <div className="row m-0">
                            <div className="col-4 course-title">
                                {showTimeDuration &&
                                    <>
                                        <img alt="Timer" src="assets/img/timer.png" height='30px'></img>
                                        <span>{String(hours).padStart(2, '0')}</span>:<span>{String(minutes).padStart(2, '0')}</span>:<span>{String(seconds).padStart(2, '0')}</span>
                                    </>
                                }
                            </div>
                            <div className="col-8 course-title">
                                <button type="button" className="btn-incident-back" onClick={onCancelHandler}>
                                    <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                                </button>
                                <span className="quiz-title">{quizExam && quizExam.quizName}</span>
                            </div>
                        </div>
                        {!loading && questions && questions.length > 0 && questions.map((m, i) => (
                            <MCQExamQuestion key={i} isExpired={isExpired} index={i} input={m} selectedIndex={selectedIndex} changeHandler={changeHandler}></MCQExamQuestion>
                        ))}
                        {loading && <div className="row m-0 pl-3 pr-3"><div className="col-12">Loading...</div></div>}
                        {!loading && questions && questions.length > 0 &&
                            <div className="row pt-1">
                                <div className="col-6">
                                    {selectedIndex > 0 &&
                                        <button
                                            type="button"
                                            className="submit-button"
                                            onClick={moveToPrevious}
                                        >
                                            Move to Previous Question
                                        </button>}
                                </div>
                                <div className="col-6">
                                    {selectedIndex < (questions.length - 1) &&
                                        <button
                                            type="button"
                                            className="float-right mr-3 submit-button"
                                            onClick={moveToNext}
                                        >
                                            Move to Next Question
                                        </button>}
                                    <ConfirmPopup />
                                    {selectedIndex === (questions.length - 1) &&
                                        <button
                                            type="button"
                                            className="float-right mr-3 submit-button"
                                            onClick={onConfirmDeleteHandler}
                                        >
                                            Submit
                                        </button>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default QuizExam;
