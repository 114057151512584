import AuthContext from "../../../auth/auth-context";
import QuizService from "../../../services/quiz.service";
import AlertService from "../../../services/alert.service";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Quizzes = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [quizzes, setQuizzes] = useState([]);
  const authContext = useContext(AuthContext);

  const assessmentAccess = authContext.getPermissions("quiz");
  if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0) {
    navigate("/");
  }

  const loadAssessments = useCallback(async () => {
    setLoading(true);
    const data = await QuizService.GetAll(authContext);
    if (data.isSuccess) {
      setLoading(false);
      setQuizzes(data.data);
    } else {
      setLoading(false);
      AlertService.error(data.errorMessage);
    }
  }, [authContext]);

  useEffect(() => {
    loadAssessments();
  }, [loadAssessments, authContext]);

  const onViewHandler = async (data) => {
    if (data.isCompleted === false) {
      navigate(`/quizzes/start/${data.id}`);
    }
  };

  return <Fragment>
    <div className="row m-0 pl-3 pr-3">
      {
        !loading && quizzes.length > 0 && quizzes.map((m, i) => (
          <div key={m.id} className="col-4 text-center cursor-pointer" onClick={() => onViewHandler(m)}>
            <div className="quiz-container">
              <div className="title">{m.quizName}</div>
              <div className="body">
                <span>Please Click Here to enter the Quiz</span>
              </div>
              {m.isCompleted &&
                <>
                  <div className="completed">
                    <center>
                      <span>Completed</span>
                    </center>
                  </div>
                  <div className="score-container pt-3">
                    <center>
                      <span>Score : <label className="score">{m.score}/100</label></span>
                    </center>
                  </div>
                </>
              }
            </div>
          </div>
        ))
      }
      {
        !loading && quizzes.length === 0 &&
        <div className="col-12 text-center">
          No Quizzes Available!!
        </div>
      }
      {loading && <div className="col-12">Loading...</div>}
    </div>
  </Fragment>
};

export default Quizzes;
